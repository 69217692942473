import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import axios from "axios";
import "./App.css";
import colors from "./colors.json";
import Header from "./components/header";
import Footer from "./components/footer";
import { NewtonsCradle, Waveform } from "@uiball/loaders";

const Work = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://mlockx.nl/paul-API/index.php",
        {}
      );
      let newresponse = response.data.reverse().map((inv) => {
        let voorImages = inv.voorImages;
        let newVoorImages = voorImages
          .replace(/[\[\]"]/g, "")
          .split(",")
          .map((img) => img.trim());

        let naImages = inv.naImages;
        let newNaImages = naImages
          .replace(/[\[\]"]/g, "")
          .split(",")
          .map((img) => img.trim());

        return { ...inv, voorImages: newVoorImages, naImages: newNaImages };
      });
      setData(newresponse);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <>
        <Header colors={colors} pagename={"work"} />
        <div className="flex flex-col justify-center items-center pl-6 pr-6 pb-6 pt-24 min-h-screen">
          <NewtonsCradle color={colors.secondaryColor} size={60} />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header colors={colors} pagename={"work"} />
      <div className="flex flex-col items-center pl-6 pr-6 pb-6 pt-24 min-h-screen">
        <div className="background"></div>
        <h1 className="itemwrapbox pl-4 font-bold text-2xl">Ons werk</h1>
        <ul className="itemwrapbox">
          {data.map((item) => (
            <Link to={`/work/${item.id}`} key={item.id}>
              {" "}
              <li
                className="itemwrap"
                style={{
                  borderColor: colors.secondaryColor,
                }}
              >
                {
                  <div className="itemwrapimagewrap rounded-lg">
                    <img
                      className="itemwrapimage"
                      src={item.naImages[0]}
                      alt="Item"
                    />
                  </div>
                }

                <h2 className="font-bold text-xl">
                  {item.title === "" ||
                  item.title === undefined ||
                  item.title === null
                    ? "N/A"
                    : item.title}
                </h2>
                <p>
                  {item.date === "" ||
                  item.date === undefined ||
                  item.date === null
                    ? "N/A"
                    : item.date}
                </p>
              </li>
            </Link>
          ))}
        </ul>
      </div>
      <Footer />
    </>
  );
};

export default Work;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ".././App.css";
import colors from "../colors.json";

const Footer = () => {
  const [year, setYear] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const date = new Date();
      const yearResult = await date.getFullYear();
      setYear(yearResult);
    }
    fetchData();
  }, []);

  return (
    <footer
      style={{
        borderTopWidth: 2,
        borderTopStyle: "solid",
        borderTopColor: colors.secondaryColor,
        background: "#eeeeee",
      }}
      className="w-full flex flex-col pt-0 mt-20 pb-0 pl-6 pr-6"
    >
      <section className="w-full flex flex-row justify-between h-24 pt-0 pb-0">
        <Link
          className="p-0 m-0 flex flex-row justify-between items-center"
          to="/"
        >
          <img
            alt="company logo"
            className="h-full object-contain"
            src="/logo.webp"
          />
        </Link>
        <div>
          <div className=" pt-0 mt-0 w-full flex justify-between h-full">
            <div className="h-full flex flex-col justify-center items-end gap-2">
              <a
                className="contact-a"
                href="mailto: schilderwerkenpb@gmail.com"
              >
                E-mail: schilderwerkenpb@gmail.com
              </a>
              <a className="contact-a" href="tel: +31615311691">
                Tel: +31 6 15311691
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full flex flex-row justify-between">
        <a
          style={{ textDecoration: "underline" }}
          target="_blank"
          href="https://mlockx.nl"
          rel="noreferrer"
        >
          Website door Melvin Lockx
        </a>
        <p>©PB Services {year}</p>
      </section>
    </footer>
  );
};

export default Footer;

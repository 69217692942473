import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Header from "./components/header";
import colors from "./colors.json";
import Footer from "./components/footer";
import { NewtonsCradle } from "@uiball/loaders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";

const ItemDetail = () => {
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [showImageSlide, setShowImageSlide] = useState(false);
  const [imageSlideNumber, setImageSlideNumber] = useState(1);

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const response = await axios.get(
          `https://mlockx.nl/paul-API/index.php?id=${id}`,
          {}
        );

        let newresponse = response.data.reverse().map((inv) => {
          let voorImages = inv.voorImages;
          let newVoorImages = voorImages
            .replace(/[\[\]"]/g, "")
            .split(",")
            .map((img) => img.trim());

          setImageSlideNumber(newVoorImages.length);

          let naImages = inv.naImages;
          let newNaImages = naImages
            .replace(/[\[\]"]/g, "")
            .split(",")
            .map((img) => img.trim());

          let combinedImages = [...newVoorImages, ...newNaImages];

          let combinedImage = combinedImages.map((img) => ({
            image: `${img}`,
            state: newVoorImages.includes(img) ? "voor" : "na",
          }));

          return {
            ...inv,
            voorImages: newVoorImages,
            naImages: newNaImages,
            image: combinedImage,
          };
        });
        setItem(newresponse[0]);
      } catch (error) {
        console.error("Error fetching item data:", error);
      }
    };

    fetchItemData();
  }, [id]);

  const ImagePreview = () => {
    return (
      <>
        <div
          className="w-5/6 lg:w-1/2 relative rounded-xl"
          style={{
            aspectRatio: 3 / 2,
            backgroundImage: `url(${item.image[0].image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div
            className="absolute left-0 bottom-0 rounded-tr-xl w-16 flex flex-row justify-center items-center"
            style={{ background: colors.secondaryColor }}
          >
            <p className="font-bold text-white text-lg p-1">
              {item.image[0].state === "voor" ? "VOOR" : "NA"}
            </p>
          </div>
          <p
            onClick={() => {
              setImageSlideNumber(0);
              setShowImageSlide(!showImageSlide);
            }}
            style={{ backdropFilter: "brightness(0.6)", cursor: "pointer" }}
            className="w-full h-full text-white flex flex-row justify-center items-center text-4xl"
          >
            + {item.voorImages.length}
          </p>
        </div>
        <div
          className="w-5/6 lg:w-1/2 relative rounded-xl overflow-hidden"
          style={{
            aspectRatio: 3 / 2,
            backgroundImage: `url(${item.naImages[0]})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div
            className="absolute left-0 bottom-0 rounded-tr-xl w-16 flex flex-row justify-center items-center"
            style={{ background: colors.secondaryColor }}
          >
            <p className="font-bold text-white text-lg p-1">NA</p>
          </div>
          <p
            onClick={() => {
              setShowImageSlide(!showImageSlide);
            }}
            style={{ backdropFilter: "brightness(0.6)", cursor: "pointer" }}
            className="w-full h-full text-white flex flex-row justify-center items-center text-4xl"
          >
            + {item.naImages.length}
          </p>
        </div>
      </>
    );
  };

  const ImageSlide = () => {
    return showImageSlide ? (
      <div
        style={{ background: "#00000080" }}
        className="w-full h-screen fixed z-20 justify-center items-center gap-24 flex flex-row left-0 top-0"
      >
        <div
          onClick={() => {
            setShowImageSlide(!showImageSlide);
            setImageSlideNumber(item.voorImages.length);
          }}
          className="-z-10 w-full h-full absolute"
        ></div>
        <button
          style={{
            opacity: imageSlideNumber === 0 ? "0" : "1",
            cursor: imageSlideNumber === 0 ? "auto" : "pointer",
          }}
          className="absolute left-0 lg:left-10"
          onClick={() => {
            if (imageSlideNumber > 0) {
              setImageSlideNumber(imageSlideNumber - 1);
            } else {
              setShowImageSlide(!showImageSlide);
              setImageSlideNumber(item.voorImages.length);
            }
          }}
        >
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            className="text-5xl lg:text-6xl"
            color="#fff"
            opacity={0.8}
          />
        </button>
        <div
          style={{
            aspectRatio: "3/2",
          }}
          className="flex flex-col justify-center items-center w-5/6 lg:w-1/2 -z-10"
        >
          <div
            className="h-full w-full relative rounded-2xl"
            style={{
              backgroundImage: `url(${item.image[imageSlideNumber].image})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              borderColor: colors.secondaryColor,
              boxShadow: "-10px 10px 7px #00000030",
            }}
          >
            <div
              className="absolute left-0 bottom-0 rounded-tr-xl rounded-bl-2xl lg:w-16 flex flex-row justify-center items-center"
              style={{ background: colors.secondaryColor }}
            >
              <p className="font-bold text-white text-lg p-1">
                {item.image[imageSlideNumber].state === "voor" ? "VOOR" : "NA"}
              </p>
            </div>
          </div>
          <h2 className="text-white text-2xl mt-2" style={{ display: "none" }}>
            Image {imageSlideNumber + 1}/{item.image.length}
          </h2>
        </div>
        <button
          style={{
            opacity: imageSlideNumber + 1 === item.image.length ? "0" : "1",
            cursor:
              imageSlideNumber + 1 === item.image.length ? "auto" : "pointer",
          }}
          className="absolute right-0 lg:right-10"
          onClick={() => {
            if (imageSlideNumber + 1 < item.image.length) {
              setImageSlideNumber(imageSlideNumber + 1);
            } else {
              setShowImageSlide(!showImageSlide);
              setImageSlideNumber(item.voorImages.length);
            }
          }}
        >
          <FontAwesomeIcon
            icon={faChevronCircleRight}
            className="text-5xl lg:text-6xl"
            color="#fff"
            opacity={0.8}
          />
        </button>
      </div>
    ) : (
      <></>
    );
  };

  if (!item) {
    return (
      <>
        <Header colors={colors} pagename={"work"} />
        <div className="flex flex-col justify-center items-center pl-6 pr-6 pb-6 pt-24 min-h-screen">
          <NewtonsCradle color={colors.secondaryColor} size={60} />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header colors={colors} pagename={"work"} />

      <div className="pl-6 pr-6 pb-6 flex flex-col gap-6 pt-24 min-h-screen">
        <div className="background"></div>
        <h1 className="font-bold text-3xl">{item.title}</h1>
        <div className="w-full flex flex-col items-center lg:items-start lg:flex-row gap-1">
          {item.image.length > 1 ? (
            <ImagePreview />
          ) : (
            <div
              className="w-full lg:w-1/2 relative rounded-lg"
              style={{
                aspectRatio: 3 / 2,
                backgroundImage: `url(${item.image[0].image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div
                className="absolute left-0 bottom-0 rounded-tr-xl w-16 flex flex-row justify-center items-center"
                style={{ background: colors.secondaryColor }}
              >
                <p className="font-bold text-white text-lg p-1">
                  {item.image[0].state === "voor" ? "VOOR" : "NA"}
                </p>
              </div>
            </div>
          )}
        </div>

        <h3 className="w-2/3">{item.description}</h3>
        <h2 className="text-sm opacity-75">Posted: {item.date}</h2>
      </div>
      <ImageSlide />
      <Footer />
    </>
  );
};

export default ItemDetail;

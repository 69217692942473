import React, { useState, useRef } from "react";
import "./App.css";
import colors from "./colors.json";
import Header from "./components/header";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "./components/footer";
import axios from "axios";

const PlaceReview = () => {
  const captchaRef = useRef(null);
  const [rating, setRating] = useState(0);
  const authToken = "1c9cb3f0935749fab11c4f9bcc0733ec";
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    if (token !== "" && token !== null && token !== undefined && rating !== 0) {
      try {
        const dataToSend = {
          name: formData.name,
          email: formData.email,
          address: formData.address,
          description: formData.description,
          rating: rating,
        };

        const response = await axios.post(
          "https://mlockx.nl/paul-API/reviews.php",
          dataToSend,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          alert("Review verstuurd!");
          setFormData({
            name: "",
            email: "",
            address: "",
            description: "",
          });
          setRating(0);
          captchaRef.current.reset();
          window.location = "/";
        } else {
          console.log(response);
          alert("Failed to send review.");
        }
      } catch (error) {
        console.error("Error sending review:", error);
      }
    } else {
      if (rating === 0) {
        alert("U moet nog een rating invullen!");
      } else {
        alert("Verifieer alstublieft dat u geen robot bent!");
      }
    }
  };

  return (
    <>
      <Header colors={colors} pagename={""} />
      <div className="pl-6 pr-6 pb-6 flex flex-row justify-center w-full min-h-screen gap-24 pt-24">
        <div className="background"></div>
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <h2 className="font-bold text-2xl">Plaats een review</h2>
            <input
              style={{ background: "#ffffff70" }}
              placeholder="Naam*"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              style={{ background: "#ffffff70" }}
              placeholder="Email adres*"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              style={{ background: "#ffffff70" }}
              placeholder="Adres*"
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
            <label className="font-bold" htmlFor="message">
              Rating:
            </label>
            <div className="w-full flex flex-row justify-evenly items-center">
              {Array(5)
                .fill()
                .map((_, i) => (
                  <h2
                    key={i}
                    className="cursor-pointer text-4xl"
                    style={{
                      color: rating >= i + 1 ? colors.secondaryColor : "black",
                    }}
                    onClick={() => {
                      setRating(i + 1);
                    }}
                  >
                    {rating >= i + 1 ? "★" : "☆"}
                  </h2>
                ))}
            </div>

            <label className="font-bold" htmlFor="message">
              Bericht:
            </label>
            <textarea
              style={{ background: "#ffffff70" }}
              placeholder="Ervaring*"
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
            <ReCAPTCHA
              sitekey={"6LeWsS4oAAAAAMetxMpASjfskU5IdMFMJf79nEkH"}
              ref={captchaRef}
            />
            <button
              className="text-center px-2 py-3 w-2/6 rounded-lg"
              style={{
                borderWidth: 2,
                borderColor: colors.secondaryColor,
                color: colors.textColor,
              }}
              type="submit"
            >
              Plaats review
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PlaceReview;

import "./App.css";
import colors from "./colors.json";
import Header from "./components/header";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import Footer from "./components/footer";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Link } from "react-router-dom";
import { NewtonsCradle } from "@uiball/loaders";
import { HashLink } from "react-router-hash-link";

const Home = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allReviews, setAllReviews] = useState([]);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://mlockx.nl/paul-API/reviews.php",
        {}
      );
      const current_reviews = response.data.filter(
        (response) => response.status === "Accepted"
      );
      setAllReviews(current_reviews);

      function getRandomReviews(current_reviews, numReviews) {
        const shuffledReviews = [...current_reviews];
        for (let i = shuffledReviews.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledReviews[i], shuffledReviews[j]] = [
            shuffledReviews[j],
            shuffledReviews[i],
          ];
        }
        return shuffledReviews.slice(0, numReviews);
      }

      const randomReviews = getRandomReviews(current_reviews, 3);
      setReviews(randomReviews);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function addStars(rating) {
    let string = "";
    for (let i = 0; i < rating; i++) {
      string = string + "★";
    }
    return string;
  }

  function getAverageScore() {
    let totalReviews = 0;
    let totalScore = 0;
    allReviews.forEach((review) => {
      totalReviews += 1;
      totalScore += Math.floor(parseInt(review.rating));
    });
    let averageScore = Math.floor(totalScore / totalReviews);
    return averageScore;
  }

  function AddTotalStars() {
    let string = "";
    for (let i = 0; i < getAverageScore(); i++) {
      string = string + "★";
    }
    return string;
  }

  if (loading) {
    return (
      <>
        <Header colors={colors} pagename={"home"} />
        <div className="flex flex-col justify-center items-center pl-6 pr-6 pb-6 pt-24 min-h-screen">
          <div className="background"></div>
          <NewtonsCradle color={colors.secondaryColor} size={60} />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header colors={colors} pagename={"home"} />
      <div className="pl-6 pr-6 pb-6 flex flex-col items-center justify-start overflow-hidden">
        <div className="background"></div>
        <section className="w-full h-screen flex flex-col justify-center items-center p-0 m-0">
          <div className="w-full h-5/6 absolute flex flex-row justify-center items-end">
            <HashLink className="z-30" to="#firstSection">
              <FontAwesomeIcon
                icon={faChevronDown}
                style={{ opacity: scrollPosition < 50 ? 1 : 0 }}
                className="text-3xl chevrondownhome"
              />
            </HashLink>
          </div>
          <div className="absolute w-full h-screen flex flex-row justify-end items-start mt-20 lg:items-center md:mt-20 overflow-hidden -z-10">
            <div className="w-2/6 paul-card">
              <img
                className="h-full w-full"
                src="https://servicespb.nl/RightImage.jpg"
                alt="Paul Bakker"
              ></img>
            </div>
          </div>
          <div className="absolute w-full h-screen flex flex-row justify-start items-end lg:items-center md:mt-18 overflow-hidden -z-10">
            <div className="w-2/6 paul-card2 bg-red-300">
              <img
                className="w-full h-full"
                src="https://cdn.discordapp.com/attachments/754440404891336816/1150160279150264372/WhatsApp_Image_2023-08-29_at_21.24.55.jpg"
                alt="Paul Bakker"
              ></img>
            </div>
          </div>
          <h1 className="text-6xl text-center font-bold mb-4 titlepage-text">
            PB Services
          </h1>
          <h2 className="text-xl text-center lg:w-1/4 md:w-3/4">
            Kwalitatief Vakmanschap voor Schilder- en Behangwerk in Den Helder
            en omstreken.
          </h2>
        </section>
        <AnimationOnScroll
          animateIn="animate__fadeInLeftBig"
          animateOnce={true}
        >
          <section
            id="firstSection"
            className="w-full flex flex-col-reverse lg:flex-row justify-center items-center lg:items-start lg:justify-evenly pt-10 pb-10 m-0"
          >
            <p className="w-full lg:w-1/3 text-s lg:text-lg">
              Als het om woningverbetering gaat, kunnen schilderen en behangen
              de meest effectieve en kostenefficiënte manieren zijn om uw ruimte
              een nieuwe look te geven. Het kan echter een hele klus zijn om
              zelf aan de slag te gaan. Dat is waar PB Services om de hoek komt
              kijken. Zij zijn experts in kwalitatief vakmanschap voor schilder-
              en behangwerk in Den Helder en omstreken. PB Services biedt een
              breed scala aan diensten, van binnenschilderwerk tot
              buitenschilderwerk, en gebruikt alleen materialen van de beste
              kwaliteit om een duurzame afwerking te garanderen. Daarom is PB
              Services de beste keuze voor al uw schilder- en
              behangwerkzaamheden en hoe zij uw huis kunnen transformeren in een
              prachtige ruimte waar u dol op zult zijn.
            </p>
            <div className="w-5/6 lg:w-1/3">
              <img
                className="object-contain w-full"
                src="https://servicespb.nl/Bus.jpg"
                alt="bus"
              ></img>
              <p className="text-gray-400">PB Services bus</p>
            </div>
          </section>
        </AnimationOnScroll>
        <AnimationOnScroll
          animateIn="animate__fadeInRightBig"
          animateOnce={true}
        >
          <section className="w-full flex flex-row justify-center pt-10 pb-10 m-0 gap-6 ">
            <p
              className="text-s lg:text-xl w-11/12 lg:w-1/2 p-10 bg-white"
              style={{
                borderWidth: "2px",
                borderColor: colors.secondaryColor,
                boxShadow: "-15px 15px 5px #00000020",
              }}
            >
              Wat PB Services onderscheidt, is onze onvermoeibare toewijding aan
              het leveren van vakmanschap van de hoogste kwaliteit. We zijn
              trots op onze aandacht voor detail en zorgen ervoor dat elke
              penseelstreek en behangtoepassing met precisie en zorg wordt
              uitgevoerd. Wij gebruiken alleen de beste materialen en technieken
              om een onberispelijke afwerking te garanderen die lang meegaat.
            </p>
          </section>
        </AnimationOnScroll>

        <AnimationOnScroll
          animateIn="animate__fadeInRightBig"
          animateOnce={true}
        >
          <section className="w-full flex flex-col justify-center items-center lg:flex-row lg:justify-evenly lg:items-start pt-10 pb-10 m-0">
            <div className="w-5/6 lg:w-1/3">
              <img
                className="object-contain"
                src="https://servicespb.nl/Haard.jpg"
                alt="haard"
              ></img>
              <p className="text-gray-400">Geschilderde open haard</p>
            </div>

            <figure className="w-full lg:w-1/3 flex flex-col  m-0 gap-6 ">
              <p className="w-full text-lg">
                Wij geloven dat communicatie en samenwerking essentieel zijn om
                het gewenste resultaat voor onze klanten te bereiken. Vanaf het
                eerste adviesgesprek tot de laatste hand werken wij nauw met u
                samen om uw visie, voorkeuren en vereisten te begrijpen. Wij
                bieden deskundig advies en begeleiding tijdens het hele proces,
                zodat u de perfecte kleuren, texturen en afwerkingen kunt kiezen
                die de esthetiek van uw ruimte verbeteren.
              </p>
              <p className="w-full text-lg">
                Wanneer u voor PB Services kiest, kunt u er zeker van zijn dat u
                in goede handen bent. We zijn uitgerust met de nieuwste
                gereedschappen en technologieën om uitzonderlijke resultaten te
                leveren. We zijn er trots op dat we projecten kunnen voltooien
                met minimale verstoring van uw dagelijkse routine.
              </p>
            </figure>
          </section>
        </AnimationOnScroll>

        <section className="w-full flex flex-row justify-center pt-10 pb-10 m-8 gap-6 ">
          <p
            className="text-s lg:text-xl w-11/12 lg:w-1/2 p-10 bg-white"
            style={{
              borderWidth: "2px",
              borderColor: colors.secondaryColor,
              boxShadow: "-15px 15px 5px #00000020",
            }}
          >
            Kortom, PB Services is uw adres voor eersteklas schilder- en
            behangservices in Den Helder en omstreken. Met hun expertise op het
            gebied van vakmanschap hebben ze zich gevestigd als de
            toonaangevende professionals in de branche. Of u nu een nieuwe
            verflaag nodig heeft of prachtig behang of renovlies, Pb Services
            zal uw verwachtingen overtreffen met hun aandacht voor detail en
            toewijding aan het leveren van uitzonderlijke resultaten. Neem geen
            genoegen met minder als het gaat om het verbeteren van de
            esthetische aantrekkingskracht van uw ruimte - kies PB Services voor
            ongeëvenaarde expertise en klanttevredenheid.
          </p>
        </section>
        <section className="w-full flex flex-col lg:justify-start lg:items-start justify-center items-center">
          <div className="w-full flex flex-col items-center justify-center mb-6">
            <h2 className="text-center font-bold text-xl">
              Gemiddelde score: {getAverageScore()}/5
            </h2>
            <h2 style={{ color: colors.secondaryColor }} className="text-4xl">
              {AddTotalStars()}
            </h2>
          </div>

          <div className="flex flex-row justify-center lg:justify-left mb-6 lg:ml-24 gap-6">
            <Link className="contact-a" to="/reviews">
              Alle reviews bekijken
            </Link>
            <p>|</p>
            <Link className="contact-a" to="/placereview">
              Plaats een review
            </Link>
          </div>

          <section className="w-full flex flex-col lg:flex-row justify-evenly items-center gap-6 lg:gap-0">
            {reviews.map((review) => (
              <figure
                key={review.ID}
                className="w-3/6 lg:w-1/4 aspect-video bg-slate-50 rounded-xl flex flex-col items-center p-4"
              >
                <h3 className="h-1/6 font-bold">{review.author}</h3>
                <h2 className="h-1/6">{review.rating}/5</h2>
                <h2 className="h-1/6 pb-4 text-yellow-300 text-2xl w-full text-center">
                  {addStars(review.rating)}
                </h2>

                <p className="h-3/6 pt-4">{review.description}</p>
              </figure>
            ))}
          </section>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Home;

import React, { useState, useRef } from "react";
import "./App.css";
import colors from "./colors.json";
import Header from "./components/header";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "./components/footer";

const Contact = () => {
  const captchaRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    if (token !== "" && token !== null && token !== undefined) {
      try {
        const response = await fetch(
          "https://mlockx.nl/paul-API/submitemail.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              name: formData.name,
              email: formData.email,
              message: formData.message,
            }).toString(),
          }
        );

        if (response.ok) {
          alert("Email sent!");
          setFormData({
            name: "",
            email: "",
            message: "",
          });
          captchaRef.current.reset();
        } else {
          console.log(response);
          alert("Failed to send email.");
        }
      } catch (error) {
        console.error("Error sending email:", error);
      }
    } else {
      alert("Please verify that you are not a robot!");
    }
  };

  return (
    <>
      <Header colors={colors} pagename={"contact"} />
      <div className="pl-6 pr-6 pb-6 flex flex-col-reverse  lg:items-start lg:flex-row justify-center w-full min-h-screen gap-24 pt-24">
        <div className="background"></div>
        <div className="flex flex-col items-left">
          <img
            className="object-contain w-1/3 lg:w-full"
            src="/logo.webp"
            alt="company logo"
          ></img>
          <p>Paul Bakker</p>
          <a className="contact-a" href="mailto: schilderwerkenpb@gmail.com">
            E-mail: schilderwerkenpb@gmail.com
          </a>
          <a className="contact-a" href="tel: +31615311691">
            Tel: +31 6 15311691
          </a>
        </div>
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <h2 className="font-bold text-2xl">Neem contact op</h2>
            <input
              style={{ background: "#ffffff70" }}
              placeholder="Naam*"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              style={{ background: "#ffffff70" }}
              placeholder="Email adres*"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label className="font-bold" htmlFor="message">
              Bericht:
            </label>
            <textarea
              style={{ background: "#ffffff70" }}
              placeholder="Bericht*"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <ReCAPTCHA
              sitekey={"6LeWsS4oAAAAAMetxMpASjfskU5IdMFMJf79nEkH"}
              ref={captchaRef}
            />
            <button
              className="text-center px-2 py-3 w-2/6 rounded-lg"
              style={{
                background: "#ffffff20",
                borderWidth: 2,
                borderColor: colors.secondaryColor,
                color: colors.textColor,
              }}
              type="submit"
            >
              verstuur
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;

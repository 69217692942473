import React, { useState } from "react";
import { Link } from "react-router-dom";
import ".././App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

const Header = ({ pagename, colors }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  return (
    <>
      <header
        style={{
          background: "#eeeeee",
          borderBottomWidth: "1px",
          borderColor: "#00000030",
        }}
        className="absolute w-full flex flex-row-reverse md:flex-row justify-between h-16 pt-0 mt-0 pb-0 pl-4 pr-4 md:mb-6 md:pl-20 md:pr-20"
      >
        <button
          className="header-mobile-link"
          onClick={() => {
            setMobileNavOpen(!mobileNavOpen);
          }}
        >
          <FontAwesomeIcon icon={faBars} className="text-4xl" />
        </button>
        <Link className="p-0 m-0" to="/">
          <img
            alt="company logo"
            className="h-full object-contain"
            src="/logo.webp"
          />
        </Link>
        <div className="header-link">
          <div className=" pt-0 mt-0 w-full flex justify-between h-full">
            <div className="h-full flex justify-evenly items-center gap-10 text-lg">
              <Link
                className="header-link"
                to="/"
                style={{
                  color:
                    pagename === "home"
                      ? colors.importantTextColor
                      : colors.textColor,
                }}
              >
                Home
              </Link>
              <Link
                className="header-link"
                to="/work"
                style={{
                  color:
                    pagename === "work"
                      ? colors.importantTextColor
                      : colors.textColor,
                }}
              >
                Werk
              </Link>
              <Link
                className="header-link"
                to="/reviews"
                style={{
                  color:
                    pagename === "reviews"
                      ? colors.importantTextColor
                      : colors.textColor,
                }}
              >
                Reviews
              </Link>
              <Link
                className="header-link"
                to="/contact"
                style={{
                  color:
                    pagename === "contact"
                      ? colors.importantTextColor
                      : colors.textColor,
                }}
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </header>
      {mobileNavOpen ? (
        <div className="fixed bg-white z-40 h-screen w-full flex flex-col justify-center items-center gap-10 text-lg">
          <div className="w-full h-full absolute">
            <button
              onClick={() => {
                setMobileNavOpen(!mobileNavOpen);
              }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                className="text-4xl text-gray-600 absolute right-3 top-3"
              />
            </button>
          </div>
          <Link
            to="/"
            style={{
              color:
                pagename === "home"
                  ? colors.importantTextColor
                  : colors.textColor,
            }}
            className="z-50"
          >
            Home
          </Link>
          <Link
            to="/work"
            style={{
              color:
                pagename === "work"
                  ? colors.importantTextColor
                  : colors.textColor,
            }}
            className="z-50"
          >
            Werk
          </Link>
          <Link
            to="/reviews"
            style={{
              color:
                pagename === "reviews"
                  ? colors.importantTextColor
                  : colors.textColor,
            }}
            className="z-50"
          >
            Reviews
          </Link>
          <Link
            to="/contact"
            style={{
              color:
                pagename === "contact"
                  ? colors.importantTextColor
                  : colors.textColor,
            }}
            className="z-50"
          >
            Contact
          </Link>
        </div>
      ) : null}
    </>
  );
};

export default Header;

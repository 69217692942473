import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import colors from "./colors.json";
import Header from "./components/header";
import Footer from "./components/footer";
import { NewtonsCradle } from "@uiball/loaders";
import { Link } from "react-router-dom";

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://mlockx.nl/paul-API/reviews.php",
        {}
      );

      let newresponse = response.data.filter(
        (response) => response.status === "Accepted"
      );
      setReviews(newresponse);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function addStars(rating) {
    let string = "";
    for (let i = 0; i < rating; i++) {
      string = string + "★";
    }
    return string;
  }

  function getAverageScore() {
    let totalReviews = 0;
    let totalScore = 0;
    reviews.forEach((review) => {
      totalReviews += 1;
      totalScore += Math.floor(parseInt(review.rating));
    });
    let averageScore = Math.floor(totalScore / totalReviews);
    return averageScore;
  }

  function AddTotalStars() {
    let string = "";
    for (let i = 0; i < getAverageScore(); i++) {
      string = string + "★";
    }
    return string;
  }

  if (loading) {
    return (
      <>
        <Header colors={colors} pagename={"reviews"} />
        <div className="flex flex-col justify-center items-center pl-6 pr-6 pb-6 pt-24 min-h-screen">
          <NewtonsCradle color={colors.secondaryColor} size={60} />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header colors={colors} pagename={"reviews"} />
      <div className="flex flex-col items-center pl-6 pr-6 pb-6 pt-24 min-h-screen">
        <div className="background"></div>
        <div className="flex flex-col items-center justify-center mb-10">
          <h1 className="font-bold text-2xl text-center">
            Gemiddelde review score: {getAverageScore()}/5
          </h1>
          <h2 style={{ color: colors.secondaryColor }} className="text-4xl">
            {AddTotalStars()}
          </h2>
        </div>

        <div>
          <div className="flex flex-row justify-center lg:justify-start mb-6 lg:ml-32 gap-6">
            <Link
              to="/placereview"
              className="rounded-lg p-4 text-white contact-a font-bold"
              style={{ background: colors.secondaryColor }}
            >
              Plaats een review
            </Link>
          </div>
          <ul className="reviewswrapbox">
            {reviews.map((review) => (
              <figure
                key={review.ID}
                className="w-5/6 lg:w-96 m-2 lg:aspect-video bg-slate-50 rounded-xl flex flex-col items-center p-4"
              >
                <h3 className="h-1/6 font-bold">{review.author}</h3>
                <h2 className="h-1/6">{review.rating}/5</h2>
                <h2 className="h-1/6 pb-4 text-yellow-300 text-2xl w-full text-center">
                  {addStars(review.rating)}
                </h2>

                <p className="h-3/6 pt-4">{review.description}</p>
              </figure>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Reviews;
